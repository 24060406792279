import i18n from 'i18next';
import { useTranslation, initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import enJSON from "./lang/en.json"
import arJSON from "./lang/ar.json"

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: { ...enJSON },
            ar: { ...arJSON },
        },
        lng: "ar",     
    });


export default i18n;