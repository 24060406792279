import vision from "../../../assets/images/vision.png";
import value from "../../../assets/images/value.png";
import message from "../../../assets/images/message.png";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import FetchData from "../../../http/FetchData";
import { endpoit } from "../../../http/URLs";

export const ThreeAbout = () => {
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const { data: getAbout } = useQuery({
    queryKey: [`getAbout${lang}`, lang],
    queryFn: () => FetchData(endpoit.GetAbout, lang),
  });

  return (
    <section className="Vision">
      <div className="container">
        <div className="row d-flex justify-content-center">
          {getAbout?.aboutList?.map((ele, index) => (
            <div
              key={index}
              className="col-md-4 col-6 d-flex flex-column align-items-center"
              data-aos="fade-up"
            >
              <img src={vision} alt="vision" />
              <div className="VisionBox my-2">
                <h3>{ele.name}</h3>
                <p>{ele.shortDescription}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
