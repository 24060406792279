import React, { useState, useEffect } from "react";
import logo from "../../assets/images/logo.png";
import { Navbar, Nav, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Header() {
  const [expanded, setExpanded] = useState(false);
  const [lng, setLang] = useState("");
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.querySelector(".navbar");

      if (window.innerWidth > 768) {
        // For larger screens (desktop/tablet), add/remove 'activee' class on scroll
        if (window.scrollY > 50) {
          navbar.classList.add("activee");
          navbar.classList.remove("mobile-active");
        } else {
          navbar.classList.remove("activee");
        }
      } else {
        // For mobile screens, add 'mobile-active' class and remove 'activee'
        if (window.scrollY > 50) {
          navbar.classList.add("mobile-active");
          navbar.classList.remove("activee");
        } else {
          navbar.classList.remove("mobile-active");
        }
      }
    };

    // Attach scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  /* const { isLoading } = useChangeLanguage(lng);
    if (isLoading) {
      return <div className="full-screen bg-black"></div>;
    } */
    const handleLanguageToggle = () => {
      const newLanguage = i18n.language === "en" ? "ar" : "en";
      setLang(newLanguage);
      i18n.changeLanguage(newLanguage);
    };

  return (
    <div className="head">
      <Navbar
        expand="lg"
        expanded={expanded}
        onToggle={(isExpanded) => setExpanded(isExpanded)}
        className="navbar"
        collapseOnSelect
      >
        <Container>
          <Navbar.Brand className="mx-auto mobilelogo" href="/">
            <img
              src={logo}
              width="150"
              className="d-inline-block align-top "
              alt="Logo"
            />
          </Navbar.Brand>

          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={() => setExpanded(!expanded)}
          />

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Link className="nav-link" to="/" onClick={() => setExpanded(false)}>
               {t("Main")}
              </Link>
              <Link className="nav-link" to="/BannerAbout" onClick={() => setExpanded(false)}>
              {t("About")}
              </Link>
              <Link className="nav-link" to="/BannerGoals" onClick={() => setExpanded(false)}>
              {t("Goals")}
                
              </Link>
            </Nav>
            <Navbar.Brand className="mx-auto weblogo" href="/">
              <img
                src={logo}
                width="150"
                className="d-inline-block align-top weblogo"
                alt="Logo"
              />
            </Navbar.Brand>
            <Nav className="me-auto">
              <Link className="nav-link" to="/BannerBenefits" onClick={() => setExpanded(false)}>
                {t("Affiliate")}
              </Link>
              <Link className="nav-link" to="/BannerAffiated" onClick={() => setExpanded(false)}>
                {t("Offices")}
              </Link>
              <Link className="nav-link" to="/BannerContact" onClick={() => setExpanded(false)}>
               {t("Contact")}
              </Link>
              <button
                  onClick={handleLanguageToggle}
                  className="nav-link me-2 ms-2"
                  id="language-icon"
                >
                  <i className="fas fa-globe"></i>
                </button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}
