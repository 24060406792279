import Layth from "../../../assets/partners/layth.png";
import aldami from "../../../assets/partners/aldami.png";
import alhummar from "../../../assets/partners/alhummar.png";
import dina from "../../../assets/partners/dina.png";
import discount from "../../../assets/partners/discount.png";
import dunya from "../../../assets/partners/dunya.png";
import express from "../../../assets/partners/express.png";
import gulf from "../../../assets/partners/gulf.png";
import marseille from "../../../assets/partners/marseille.png";
import rayan from "../../../assets/partners/rayan.png";
import choice from "../../../assets/partners/smart choice.png";
import yaqout from "../../../assets/partners/yaqout.png";

import days from "../../../assets/partners/360days.png";
import alhayba from "../../../assets/partners/alhayba.png";
import alrafedin from "../../../assets/partners/alrafedin.png";
import celeopetra from "../../../assets/partners/celeopetra.png";
import elegant from "../../../assets/partners/elegant.png";
import garden from "../../../assets/partners/garden.png";
import hamdi from "../../../assets/partners/hamdi.png";
import milano from "../../../assets/partners/milano.png";
import modern from "../../../assets/partners/modern.png";
import virgina from "../../../assets/partners/virgina.png";
import tatra from "../../../assets/partners/tatra.png";
import sucsess from "../../../assets/partners/sucsess.png";
import montecarlo from "../../../assets/partners/montecarlo.png";
import transit from "../../../assets/partners/transit.png";
import theater from "../../../assets/partners/theater.png";
import eyas from "../../../assets/partners/eyas.png";
import melia from "../../../assets/partners/melia.png";
import alwaha from "../../../assets/partners/alwaha.png";
import redcarppet from "../../../assets/partners/redcarppet.png";
import amatest from "../../../assets/partners/amatest.png";
import mazaya from "../../../assets/partners/mazaya.png";
import alfa5ameh from "../../../assets/partners/alfa5ameh.png";
import cordoba from "../../../assets/partners/cordoba.png";
import Exist from "../../../assets/partners/exist.png";
import Rama from "../../../assets//partners/rama.png";
import Road from "../../../assets/partners/green road.png";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const companies = [
  {
    src: Layth,
    alt: "Layth",
    name: "ليث لتأجير السيارات",
    path2: "https://www.laythcars.com/",
  },
  { src: aldami, alt: "aldami", name: "الدماسي لتأجير السيارات" },
  {
    src: alhummar,
    alt: "alhummar",
    name: "الحمر لتأجير السيارات",
    path2: "https://www.facebook.com/AlHummarJo/?locale=ar_AR",
  },
  { src: dina, alt: "dina", name: "دينا لتأجير السيارات" },
  {
    src: discount,
    alt: "discount",
    name: "ديسكاونت لتأجير السيارات",
    path2: "https://www.facebook.com/DiscountCarJo",
  },
  { src: dunya, alt: "dunya", name: "دنيا لتأجير السيارات" },
  { src: express, alt: "express", name: "اكسبرس لتأجير السيارات" },
  { src: gulf, alt: "gulf", name: "جولف لتأجير السيارات" },
  {
    src: marseille,
    alt: "marseille",
    name: "مارسيليا لتأجير السيارات",
    path2: "https://www.facebook.com/MARSEILLE.JO/",
  },
  {
    src: rayan,
    alt: "rayan",
    name: "ريان لتأجير السيارات",
    path2:
      "https://www.facebook.com/p/Al-Rayyan-Car-Rental-%D8%B4%D8%B1%D9%83%D8%A9-%D8%A7%D9%84%D8%B1%D9%8A%D8%A7%D9%86-%D9%84%D8%AA%D8%A3%D8%AC%D9%8A%D8%B1-%D8%A7%D9%84%D8%B3%D9%8A%D8%A7%D8%B1%D8%A7%D8%AA-%D8%A7%D9%84%D8%B3%D9%8A%D8%A7%D8%AD%D9%8A%D8%A9-61552342893303/?_rdr",
  },
  {
    src: choice,
    alt: "choice",
    name: "الاختيار الذكي لتأجير السيارات",
    path2: "https://www.facebook.com/Yaqoutforrentacar",
  },
  { src: yaqout, alt: "yaqout", name: "الياقوت لتأجير السيارات" },
  {
    src: modern,
    alt: "modern",
    name: "مودرين لتأجير السيارات",
    path2: "https://www.facebook.com/mazen.yazory",
  },
  {
    src: montecarlo,
    alt: "montecarlo",
    name: "مونتي كارلو لتأجير السيارات",
    path2: "https://montecar.com/",
  },
  {
    src: milano,
    alt: "milano",
    name: "ميلانو لتأجير السيارات",
    path2: "https://www.facebook.com/MilanoCRJO/",
  },
  {
    src: days,
    alt: "days",
    name: "360يوم لتأجير السيارات",
    path2: "https://www.360days.rent/",
  },
  { src: alhayba, alt: "alhayba", name: "الهيبة لتأجير السيارات" },
  {
    src: tatra,
    alt: "tatra",
    name: "تترا لتأجير السيارات",
    path2: "https://www.facebook.com/tatrarent",
  },
  { src: sucsess, alt: "sucsess", name: "النجاح لتأجير السيارات" },
  {
    src: virgina,
    alt: "virgina",
    name: "فيرجينا لتأجير السيارات",
    path2:
      "https://www.facebook.com/people/%D8%B4%D8%B1%D9%83%D8%A9-%D9%81%D8%B1%D8%AC%D9%8A%D9%86%D9%8A%D8%A7-%D9%84%D8%AA%D8%A3%D8%AC%D9%8A%D8%B1-%D8%A7%D9%84%D8%B3%D9%8A%D8%A7%D8%B1%D8%A7%D8%AA-%D8%A7%D9%84%D8%B3%D9%8A%D8%A7%D8%AD%D9%8A%D9%87/100058858579463/",
  },
  { src: hamdi, alt: "hamdi", name: "حمدي لتأجير السيارات" },
  { src: garden, alt: "garden", name: "الحديقة لتأجير السيارات" },
  {
    src: elegant,
    alt: "elegant",
    name: "الأنيق لتأجير السيارات",
    path2:
      "https://www.facebook.com/p/Elegant-car-rental-%D8%A7%D9%84%D8%A7%D9%86%D9%8A%D9%82-%D9%84%D8%AA%D8%A7%D8%AC%D9%8A%D8%B1-%D8%A7%D9%84%D8%B3%D9%8A%D8%A7%D8%B1%D8%A7%D8%AA-%D8%A7%D9%84%D8%B3%D9%8A%D8%A7%D8%AD%D9%8A%D8%A9-100089282330902/?_rdr",
  },
  {
    src: celeopetra,
    alt: "celeopetra",
    name: "كيليوبتلرا لتأجير السيارات",
    path2:
      "https://www.facebook.com/people/Cleopetra-Car-Rental-%D9%83%D9%84%D9%8A%D9%88%D8%A8%D8%AA%D8%B1%D8%A7-%D9%84%D8%AA%D8%A3%D8%AC%D9%8A%D8%B1-%D8%A7%D9%84%D8%B3%D9%8A%D8%A7%D8%B1%D8%A7%D8%AA-%D8%A7%D9%84%D8%B3%D9%8A%D8%A7%D8%AD%D9%8A%D8%A9/100063713659483/",
  },
  {
    src: alrafedin,
    alt: "alrafedin",
    name: "الرافيدين لتأجير السيارات",
    path2: "https://ms-my.facebook.com/rafidainrentacar",
  },
  { src: theater, alt: "theater", name: "المسرح لتأجير السيارات" },
  {
    src: amatest,
    alt: "amatest",
    name: "أماتيست لتأجير السيارات",
    path2: "https://www.facebook.com/AmatestForCars/",
  },
  { src: melia, alt: "melia", name: "ميلا لتأجير السيارات" },
  { src: eyas, alt: "eyas", name: "اياس لتأجير السيارات" },
  { src: redcarppet, alt: "redcarppet", name: "ريدكاربت لتأجير السيارات" },
  {
    src: alwaha,
    alt: "alwaha",
    name: "الواحة لتأجير السيارات",
    path2: "https://alwaharent.com/",
  },
  { src: mazaya, alt: "mazaya", name: "مزايا لتأجير السيارات" },
  {
    src: alfa5ameh,
    alt: "alfa5ameh",
    name: "الفخامة لتأجير السيارات",
    path2: "https://www.facebook.com/fakhama.rental/",
  },
  {
    src: cordoba,
    alt: "cordoba",
    name: "قرطبة لتأجير السيارات",
    path2: "https://cordobajo.com/",
  },
  {
    src: Exist,
    alt: "Exist",
    name: "الحقيقة الأولى لتأجير السيارات",
    path2: "https://www.facebook.com/The.One.Exist/",
  },
  {
    src: Road,
    alt: "Road",
    name: " الطريق الاخضر لتأجير السيارات",
    path2: "https://greenroadcarrental.online/",
  },
  {
    src: Rama,
    alt: "Rama",
    name: "راما لتأجير السيارات",
    path2: "https://usavejo.com/",
  },
];

export const AllCompany = ({ data }) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");

  const sortedCompanies = data?.sort((a, b) => a.rank - b.rank);

  const filteredCompanies = sortedCompanies?.filter((company) =>
    company.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  console.log(filteredCompanies);
  return (
    <div className="AllCompany my-5">
      <div className="container">
        <div className="row mb-4">
          <div className="col-12">
            <input
              type="text"
              className="form-control"
              placeholder={t("Search")}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
        <div className="row">
          {filteredCompanies?.map((company, index) => (
            <div className="col-md-2 col-6 my-3" key={index}>
              <a href={company.path} className="Linklogo">
                <img
                  src={`${process.env.REACT_APP_BASE_IMAGE}${company.fullImagePath}_200x200.webp`}
                  alt={company.alt}
                  className="w-100"
                />
                <h6>{company.name}</h6>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

// console.log(store());

/* 
import { useState, useEffect } from 'react';

export const AllCompany = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [data, setData] = useState([]);
  console.log(data)
  const fetchData = () => {
    fetch(`https://croaadmin.kensoftware.com/admin/api/Store/List?search=${searchTerm}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'User-Agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.124 Safari/537.36',
        'Referer': 'https://croaadmin.kensoftware.com',
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        console.error('There has been a problem with your fetch operation:', error);
      });
  };

  useEffect(() => {
    fetchData();
  }, [searchTerm]);


  return (
    <div className="AllCompany my-5">
      <div className="container">
        <div className="row mb-4">
          <div className="col-12">
            <input
              type="text"
              className="form-control"
              placeholder="ابحث عن شركة..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
        <div className="row">
          {data?.value?.data
            ?.map((company, index) => (
              <div className="col-md-2 col-6 my-3" key={index}>
                <a href={company.website} className="Linklogo" target="_blank" rel="noopener noreferrer">
                  <img src={company.locationImg} alt={company.alt} className="w-100" />
                  <h6>{company.nameEn}</h6>
                </a>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
 */
