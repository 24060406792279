import { useTranslation } from "react-i18next";
import contactBanner from "../../../assets/images/ImageConytact.jpg";

export const BannerContact = () => {
  const { t } = useTranslation();
  return (
    <section
      className="Banner"
      style={{ backgroundImage: `url(${contactBanner})` }}
    >
      <div className="ovelay"></div>
      <div className="container position-relative">
        <div className="row">
          <div className="col-md-7 m-auto  d-flex flex-column align-items-center">
            <h1 className="text-white">{t("Contact")}</h1>
          </div>
        </div>
      </div>
    </section>
  );
};
