import "./assets/styles/headercss.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/styles/Home.css";
import "./assets/styles/footer.css";
import "./assets/styles/responsive.css";
import "./assets/styles/AboutUs.css";
import "./assets/styles/ContactUs.css";
import "./assets/styles/Goals.css";
import "./assets/styles/Benefits.css";
import "aos/dist/aos.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Header from "./containers/layout/Header";
import { HomePage } from "./pages/home-page/Home";
import Aos from "aos";
import { useEffect } from "react";
import { Footer } from "./containers/layout/Footer";
import { AboutUs } from "./pages/aboutus-page/AboutUs";
import { ContactUs } from "./pages/ContactUs-page/ContactUs";
import { Goals } from "./pages/Goals-pages/Golas";
import { Benefits } from "./pages/Benefits-page/Benefits";
import { Affiated } from "./pages/Affiliatedcompanies/Affiliated";
import { useTranslation } from "react-i18next";

import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'

const queryClient = new QueryClient()

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    Aos.init({ duration: 1500, once: true });
  }, []);

  useEffect(() => {
    document.body.className = i18n.language === "ar" ? "rtl" : "ltr";
    document.body.style.direction = i18n.language === "ar" ? "rtl" : "ltr";
    document.body.setAttribute("dir", i18n.language === "ar" ? "rtl" : "ltr");
    document.body.style.textAlign = i18n.language === "ar" ? "right" : "left";
  }, [i18n.language]);

  return (
    <>
      <Router>
        <ScrollToTop />
        <QueryClientProvider client={queryClient}>
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/BannerAbout" element={<AboutUs />} />
          <Route path="/BannerContact" element={<ContactUs />} />
          <Route path="/BannerGoals" element={<Goals />} />
          <Route path="/BannerBenefits" element={<Benefits />} />
          <Route path="/BannerAffiated" element={<Affiated />} />
        </Routes>
        <Footer />
        </QueryClientProvider>
      </Router>
    </>
  );
}

export default App;
