export const endpoit = {
  HomeSwiper: "Home/GetSwiper",
  HomeGetGoals: "Home/GetGoals",
  HomeGetMembers: "Home/GetMembers",
  HomeGetOffices: "Home/GetOffices",
  HomeGetPages: "Home/GetPages",
  GetAssociationGoals: "Home/GetAssociationGoals",
  GetFeatures: "Home/GetFeatures",
  GetAboutNumbers: "Home/GetAboutNumbers",
  GetOfficeOwner: "Home/GetOfficeOwner",
  GetAbout: "Home/GetAbout",
  GetPeriods: "Home/GetPeriods",
};
