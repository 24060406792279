export const Map = ({ map }) => {
  console.log(map, "dddd");

  return (
    <section className="Map">
      <div className="container">
        <h1>{map?.label}</h1>
        <div className="row my-4">
          <div className="col-md-12" data-aos="fade-up">
            <iframe
              src={map?.path}
              width="100%"
              height="450"
              frameborder="0"
              style={{ border: 0 }}
              allowfullscreen=""
              aria-hidden="false"
              tabindex="0"
              title={`Map of ${map?.label}`}
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
};
