import GoalsBanner from "../../../assets/images/Goals.jpg";

export const BannerGoals = ({ data }) => {
  console.log(data);
  return (
    <section
      className="Banner"
      style={{ backgroundImage: `url(${GoalsBanner})` }}
    >
      <div className="ovelay"></div>
      <div className="container position-relative">
        <div className="row">
          <div className="col-md-7 m-auto  d-flex flex-column align-items-center">
            <h1 className="text-white" data-aos="fade-up">
              {" "}
              {data}
            </h1>
          </div>
        </div>
      </div>
    </section>
  );
};
