import { useTranslation}  from "react-i18next";
import logo from "../../assets/images/logo.png";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

export const Footer = () => {
  const {t} = useTranslation();

  return (
    <section className="Footer pt-5">
      <div className="overlay"></div>
      <div className="BoxSubscribtion">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <h3>{t("Subscribe-News")}</h3>
            </div>
            <div className="col-md-5">
              <form className="subscribe_form">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    name="email"
                    placeholder={t("email")}
                  />
                  <span className="input-group-btn">
                    <button className="btn btn-default" type="button">
                      {t("Subscribe")}
                    </button>
                  </span>
                </div>
              </form>
              <p style={{ fontWeight: "bold" }}>
              {t("policy-care")}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container py-5  position-relative">
        <div className="row">
          <div className="col-md-3 my-4">
            <Link to="/">
              <img src={logo} className="w-50"/>
            </Link>
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-3 col-6">
                <h6>{t("Syndicate")}</h6>
                <ul className="list-inline ">
                  <div>
                    <HashLink to="/BannerAbout#section2">{t("About-Syndicate")}</HashLink>
                  </div>
                  <div>
                    <HashLink to="/BannerAbout#section3">{t("Vision")} </HashLink>
                  </div>
                  <div>
                    <HashLink to="/BannerAbout#section5">{t("Captains")} </HashLink>
                  </div>
                </ul>
              </div>
              <div className="col-md-3 col-6">
                <h6>{t("Goal")}</h6>
                <ul className="list-inline">
                  <a href="/BannerGoals">
                    {" "}
                    <li>{t("Goals")}</li>
                  </a>
                  <a href="/BannerBenefits">
                    <li>{t("Discover-Affiliate")}</li>
                  </a>
                </ul>
              </div>
              <div className="col-md-3 col-6">
                <h6>{t("Social-Media")}</h6>
                <div className="Socila">
                  <a href="https://web.facebook.com/SJIFZ/" traget="_blank">
                    <i className="fa-brands fa-facebook"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container position-relative">
        <div className="row">
          <p className="text-center text-white fw-bold">
            {t("copy-right")}
          </p>
        </div>
      </div>
    </section>
  );
};
