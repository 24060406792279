import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import FetchData from "../../../http/FetchData";
import { endpoit } from "../../../http/URLs";

export const FourAbout = () => {
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const { data: aboutNumbers } = useQuery({
    queryKey: [`aboutNumbers${lang}`, lang],
    queryFn: () => FetchData(endpoit.GetAbout, lang),
  });

  return (
    <section className="counter my-5">
      <div className="container py-5">
        <div className="row d-flex justify-content-center">
          {aboutNumbers?.numbers?.map((ele, index) => (
            <div ket={index} className="col-md-3 my-4 col-6">
              <div className="BoxCount" data-aos="fade-up">
                <h1>{ele.name}</h1>
                <h6>{ele.extraInfo}</h6>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
