import najati from "../../../assets/Team/najati.png";
import najeeb from "../../../assets/Team/najeeb.png";
import hazeem from "../../../assets/Team/hazeem.png";
import marwan from "../../../assets/Team/Group 65.png";
import jlog from "../../../assets/Team/jlog.png";
import pdf from "../../../assets/intereorSystem.pdf";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import FetchData from "../../../http/FetchData";
import { endpoit } from "../../../http/URLs";
export const Member = () => {
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const { data: officeOwner } = useQuery({
    queryKey: [`officeOwner${lang}`, lang],
    queryFn: () => FetchData(endpoit.GetOfficeOwner, lang),
  });
  console.log(officeOwner);
  return (
    <>
      <section className="Member">
        <div className="container">
          <h1 className="text-center my-5">{officeOwner?.officeOwner?.name}</h1>
          <div className="row d-flex justify-content-center">
            {officeOwner?.officeOwnerList?.map((ele, index) => (
              <div key={index} className="col-md-4 col-6 my-3">
                <div className="pinkBac">
                  <img
                    src={`${process.env.REACT_APP_BASE_IMAGE}${ele.fullImagePath}_600x600.webp`}
                    alt="teampic"
                    className="w-100"
                  />
                  <h6>{ele.extraInfo}</h6>
                  <h3> {ele.name}</h3>
                  <h5
                    dangerouslySetInnerHTML={{ __html: ele.description }}
                  ></h5>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};
