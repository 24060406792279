// src/ContactForm.js
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaPhoneAlt } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { IoMail } from "react-icons/io5";

const ContactForm = ({ data }) => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Data Submitted:", formData);
  };
  console.log(data);

  return (
    <section className="formsCont my-5">
      <div className="container">
        <h1>{t("Questions")}</h1>
        <div className="row my-5">
          <div className="col-md-8 my-4" data-aos="fade-left">
            <form onSubmit={handleSubmit}>
              <div style={{ marginBottom: "15px" }}>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder={t("Name")}
                  value={formData.name}
                  onChange={handleChange}
                  required
                  style={{ width: "100%", padding: "8px" }}
                />
              </div>
              <div style={{ marginBottom: "15px" }}>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder={t("Email")}
                  value={formData.email}
                  onChange={handleChange}
                  required
                  style={{ width: "100%", padding: "8px" }}
                />
              </div>
              <div style={{ marginBottom: "15px" }}>
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  placeholder={t("Phone")}
                  value={formData.phone}
                  onChange={handleChange}
                  required
                  style={{ width: "100%", padding: "8px" }}
                />
              </div>
              <div style={{ marginBottom: "15px" }}>
                <textarea
                  id="message"
                  name="message"
                  placeholder={t("Message")}
                  value={formData.message}
                  onChange={handleChange}
                  required
                  style={{ width: "100%", padding: "8px", height: "100px" }}
                />
              </div>
              <button
                className="RedButton"
                type="submit"
                style={{ padding: "10px 20px" }}
              >
                {t("Send")}
              </button>
            </form>
          </div>
          <div className="col-md-4" data-aos="fade-right">
            <div className="BoxContact p-3">
              <h3 className="py-3">{data?.headQuarter?.label}</h3>
              <div className="content py-2">
                <FaLocationDot className="icon mx-2" />
                <p>{data?.headQuarter?.keywords}</p>
              </div>
              <div className="content py-2">
                <IoMail className="icon mx-2" />
                <p>{data?.email?.keywords}</p>
              </div>
              <div className="content py-2">
                <FaPhoneAlt className="icon mx-2" />
                <p>{data?.phone1?.keywords}</p>
              </div>
              <div className="content py-2">
                <FaPhoneAlt className="icon mx-2" />
                <p>{data?.phone2?.keywords}</p>
              </div>
              <div className="content py-2">
                <FaPhoneAlt className="icon mx-2" />
                <p>
                  ({data?.captainNumber?.label} ){" "}
                  {data?.numberDirictor?.keywords}
                </p>
              </div>
              <div className="content py-2">
                <FaPhoneAlt className="icon mx-2" />
                <p>
                  {" "}
                  ({data?.numberDirictor?.label}){" "}
                  {data?.numberDirictor?.keywords}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
