import vision from "../../../assets/images/vision.png";
import GreenVector from "../../../assets/images/Green.png";
import RedVector from "../../../assets/images/Red.png";

export const GoalsAbout = ({ data }) => {
  return (
    <section className="Goals">
      <div className="container my-5 py-5">
        <div className="row d-flex justify-content-center">
          {data?.map((ele, index) => (
            <div
              key={index}
              className="col-md-4 col-6 my-4 d-flex flex-column align-items-center"
              data-aos="fade-up"
            >
              <img src={vision} alt="vision" />
              <div className="VisionBox my-2">
                <h6>{ele.shortDescription}</h6>
              </div>
            </div>
          ))}
        </div>
      </div>
      <img src={GreenVector} alt="" className="GreenVector" />
      <img src={RedVector} alt="" className="RedVector" />
    </section>
  );
};
