import teampic from "../../../assets/images/team mmember.png";
import petra from "../../../assets/images/petra.jpg";
import WadiRum from "../../../assets/images/WadiRum.jpg";
import Aqaba from "../../../assets/images/Aqaba.jpg";
import jerash from "../../../assets/images/jerash.jpg";
import Dead from "../../../assets/images/Dead sea.jpg";
import Romani from "../../../assets/images/Romani.jpg";
import Team1 from "../../../assets/Team/Group 65.png";
import Team2 from "../../../assets/Team/Ameen sr.png";
import Team3 from "../../../assets/Team/member1.png";
import Team4 from "../../../assets/Team/member2.png";
import Team5 from "../../../assets/Team/member3.png";
import Team6 from "../../../assets/Team/member4.png";
import Team7 from "../../../assets/Team/member5.png";
import Team8 from "../../../assets/Team/member6.png";
import Team9 from "../../../assets/Team/member7.png";
import Team10 from "../../../assets/Team/manger.png";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import FetchData from "../../../http/FetchData";
import { endpoit } from "../../../http/URLs";

export const FiveSection = () => {
  const { i18n, t } = useTranslation();
  const lang = i18n.language;

  const { data: membersData } = useQuery({
    queryKey: [`membersData${lang}`, lang],
    queryFn: () => FetchData(endpoit.HomeGetMembers, lang),
  });

  membersData?.memberList?.sort((a, b) => a.rank - b.rank);

  return (
    <>
      <section className="Team">
        <div className="Team py-5">
          <div className="container">
            <h6 className="text-center" data-aos="fade-up">
              {t("team")}
            </h6>
            <h2 className="text-center" data-aos="fade-up">
              {membersData?.member?.name}
            </h2>
            <div className="row">
              {
                <div className="col-md-4 my-4 m-auto" data-aos="fade-up">
                  <div className="pinkBac">
                    <img
                      src={`${process.env.REACT_APP_BASE_IMAGE}${membersData?.memberList[0]?.fullImagePath}_600x600.webp`}
                      alt="teampic"
                      className="w-100"
                    />
                    <h3>{membersData?.memberList[0]?.name}</h3>
                    <h6>{membersData?.memberList[0]?.extraInfo}</h6>
                  </div>
                </div>
              }
            </div>
            <div className="row my-5" data-aos="fade-up">
              {membersData?.memberList?.slice(1).map((ele, index) => (
                <div
                  className="col-md-3 my-4 col-6"
                  data-aos="fade-up"
                  key={index}
                >
                  <div className="pinkBac">
                    <img
                      src={`${process.env.REACT_APP_BASE_IMAGE}${ele.fullImagePath}_600x600.webp`}
                      alt="teampic"
                      className="w-100"
                    />
                    <h3>{ele.name}</h3>
                    <h6>{ele.extraInfo}</h6>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section className="Tourism mt-5">
        <div className="overlay"></div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4 p-0 col-6">
              <img src={petra} alt="petra" className="w-100" />
            </div>
            <div className="col-md-4 p-0 col-6">
              <img src={Aqaba} alt="Aqaba" className="w-100" />
            </div>
            <div className="col-md-4 p-0 col-6">
              <img src={WadiRum} alt="WadiRum" className="w-100" />
            </div>
            <div className="col-md-4 p-0 col-6">
              <img src={Dead} alt="Dead" className="w-100" />
            </div>
            <div className="col-md-4 p-0 col-6">
              <img src={jerash} alt="jerash" className="w-100" />
            </div>
            <div className="col-md-4 p-0 col-6">
              <img src={Romani} alt="Romani" className="w-100" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
