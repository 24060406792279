import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import comblain from "../../../assets/images/complain.png";
import Suggestion from "../../../assets/images/suggestion.png";
import { useTranslation } from "react-i18next";

const ComplaintInnovationForms = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleShowModal2 = () => setShowModal2(true);
  const handleCloseModal2 = () => setShowModal2(false);

  return (
    <section className="Comblain my-5">
      <div className="container">
        <h2 className="text-center lighter_color">{t("Suggestions")}</h2>
        <div
          className="row py-5 d-flex justify-content-evenly"
          data-aos="fade-zoom-in"
          data-aos-duration="1500"
        >
          <div className="col-md-5 col-6">
            <Button
              className="btn Complaint_Cards_green"
              onClick={handleShowModal}
            >
              <div className="text-center">
                <img src={Suggestion} alt="microfon" className="w-50" />
                <h3 className="py-3 dark_green">{t("Submitting_S")}</h3>
              </div>
            </Button>

            <Modal
              show={showModal}
              onHide={handleCloseModal}
              centered
              className="ModelShow"
            >
              <Modal.Header closeButton className="justify-content-between">
                <Modal.Title> {t("SuggestionForm")}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form
                  action="/submit_complaint"
                  method="post"
                  className="FormComblain"
                >
                  <Form.Group controlId="name">
                    <Form.Label>{t("Name")}</Form.Label>
                    <Form.Control type="text" name="name" required />
                  </Form.Group>

                  <Form.Group controlId="phone">
                    <Form.Label> {t("Phone")}</Form.Label>
                    <Form.Control type="tel" name="phone" required />
                  </Form.Group>

                  <Form.Group controlId="email">
                    <Form.Label>{t("Email")}</Form.Label>
                    <Form.Control type="email" name="email" required />
                  </Form.Group>

                  <Form.Group controlId="message">
                    <Form.Label>{t("MessageAndSuggestion")}</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="message"
                      rows="4"
                      required
                    />
                  </Form.Group>

                  <Button className="RedButton" type="submit">
                    {t("Send")}
                  </Button>
                </Form>
              </Modal.Body>
            </Modal>
          </div>

          <div className="col-md-5 col-6">
            <Button
              className="btn Complaint_Cards_red"
              onClick={handleShowModal2}
            >
              <div className="text-center">
                <img src={comblain} alt="message and face" className="w-50" />
                <h3 className="py-3 dark_red">{t("complaint")}</h3>
              </div>
            </Button>

            <Modal
              show={showModal2}
              onHide={handleCloseModal2}
              centered
              className="ModelShow"
            >
              <Modal.Header closeButton className="justify-content-between">
                <Modal.Title>{t("ComplaintForm")}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form
                  action="/submit_complaint"
                  method="post"
                  className="FormComblain"
                >
                  <Form.Group controlId="name">
                    <Form.Label>{t("Name")}</Form.Label>
                    <Form.Control type="text" name="name" required />
                  </Form.Group>

                  <Form.Group controlId="phone">
                    <Form.Label>{t("Phone")}</Form.Label>
                    <Form.Control type="tel" name="phone" required />
                  </Form.Group>

                  <Form.Group controlId="email">
                    <Form.Label>{t("Email")} </Form.Label>
                    <Form.Control type="email" name="email" required />
                  </Form.Group>

                  <Form.Group controlId="message">
                    <Form.Label>{t("MessageAndComplaint")}</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="message"
                      rows="4"
                      required
                    />
                  </Form.Group>

                  <Button className="RedButton" type="submit">
                    {t("Send")}
                  </Button>
                </Form>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ComplaintInnovationForms;
