import PMember1 from "../../../assets/Team/PastMember1.jpg";
import PMember2 from "../../../assets/Team/PastMember2.jpg";
import PMember3 from "../../../assets/Team/PastMember3.jpg";
import PMember5 from "../../../assets/Team/2023.jpg";
import PMember4 from "../../../assets/Team/Group 145.png";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import FetchData from "../../../http/FetchData";
import { endpoit } from "../../../http/URLs";
export const SixSection = () => {
  const { i18n, t } = useTranslation();
  const lang = i18n.language;
  const { data: periods } = useQuery({
    queryKey: [`periods${lang}`, lang],
    queryFn: () => FetchData(endpoit.GetPeriods, lang),
  });
  console.log(
    `${process.env.REACT_APP_BASE_IMAGE}${periods?.periods[4]?.fullImagePath}_1920x1000.webp`
  );

  periods?.periods?.sort((a, b) => a.rank - b.rank);
  return (
    <section className="PastMember">
      <div className="container">
        <div className="row">
          {periods?.periods?.map((ele, index) => (
            <div key={index} className="col-md-4  my-3">
              <div className="BoxMember">
                <img
                  src={`${process.env.REACT_APP_BASE_IMAGE}${ele.fullImagePath}_1920x1080.webp`}
                  alt="PastMember1"
                  className="w-100"
                />
                <ul dangerouslySetInnerHTML={{ __html: ele.description }}></ul>
                <div className="Time d-flex justify-justify-content-evenly ">
                  <h3>{t("Period")}</h3>
                  <h4>{ele.name}</h4>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
