import { useTranslation } from "react-i18next";
import { BannerAbout } from "./componentsAbout/BannerAbout";
import { Member } from "./componentsAbout/fiveSection";
import { FourAbout } from "./componentsAbout/FourAbout";
import { SecondAbout } from "./componentsAbout/SecondAbout";
import { SixSection } from "./componentsAbout/sixSection";
import { ThreeAbout } from "./componentsAbout/ThreeAbout";
import { useQuery } from "@tanstack/react-query";
import FetchData from "../../http/FetchData";
import { endpoit } from "../../http/URLs";

export const AboutUs = () => {
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const { data: pages } = useQuery({
    queryKey: [`pages${lang}`, lang],
    queryFn: () => FetchData(endpoit.HomeGetPages, lang),
  });
  console.log(pages?.aboutHome?.label);
  return (
    <div>
      <div>
        <BannerAbout data={pages?.aboutHome?.label} />
      </div>
      <div id="section2">
        {" "}
        <SecondAbout data={pages?.aboutHome?.text} />
      </div>
      <div id="section3">
        {" "}
        <ThreeAbout />
      </div>
      <div id="section4">
        <FourAbout />
      </div>
      <div id="section5">
        <Member />
      </div>
      <div id="section6">
        {" "}
        <SixSection />
      </div>
    </div>
  );
};
