export const SecondSection = ({ data }) => {
  return (
    <section className="Benefits my-5">
      <div className="container">
        <div className="row d-flex justify-content-center">
          {data?.map((ele, index) => (
            <div className="col-md-4 col-6 my-3" data-aos="fade-up">
              <div className="BenefitsBox my-2">
                <h6>{ele.name}</h6>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
