import { useTranslation } from "react-i18next";
import Vector from "../../../assets/images/Vector.png";
import FetchData from "../../../http/FetchData";
import { endpoit } from "../../../http/URLs";
import { useQuery } from "@tanstack/react-query";
export const FourSection = () => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const { data: goalsData } = useQuery({
    queryKey: [`goalsData${lang}`, lang],
    queryFn: () => FetchData(endpoit.HomeGetGoals, lang),
  });
  return (
    <section className="Affiliation">
      <div className="overlay"></div>
      <div className="container py-5 position-relative">
        <div className="row">
          <div className="col-md-3 mb-5" data-aos="fade-up">
            <h6
              dangerouslySetInnerHTML={{
                __html: goalsData?.goals?.description,
              }}
            ></h6>
            <h2 className="text-white">{goalsData?.goals?.name}</h2>
            <a href="/BannerGoals" className="WhiteButton">
              {t("View-More")}
            </a>
          </div>
          <div className="col-md-9">
            <div className="row">
              {goalsData?.goalsList.map((item, index) => (
                <div
                  key={index}
                  className="col-md-4 my-2 col-6"
                  data-aos="fade-up"
                >
                  <div className="Box">
                    <img src={Vector} alt="Vector" />
                    <h3 className="text-white">{item.name}</h3>

                    <p
                      className="text-white"
                      dangerouslySetInnerHTML={{
                        __html: item.description,
                      }}
                    ></p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
