import CarImg from "../../../assets/images/mercedes.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { Autoplay, EffectFade } from "swiper/modules";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import FetchData from "../../../http/FetchData";
import { endpoit } from "../../../http/URLs";

export default function SectionOne({ info }) {
  const { i18n, t } = useTranslation();
  const lang = i18n.language;

  const { data: homeSwiper } = useQuery({
    queryKey: ["homeSwiper", lang],
    queryFn: () => FetchData(endpoit.HomeSwiper, lang),
  });

  console.log(homeSwiper?.swiper);

  return (
    <div className="Home d-flex align-items-center">
      <div className="overlay"></div>
      <Swiper
        modules={[Autoplay, EffectFade]}
        effect="fade"
        spaceBetween={50}
        slidesPerView={1}
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
      >
        {homeSwiper?.swiper?.map((ele, index) => (
          <SwiperSlide key={index}>
            <img
              src={`${process.env.REACT_APP_BASE_IMAGE}${ele.fullImagePath}_1920x1080.webp`}
              alt={ele.name}
              className="w-100"
            />
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="container">
        <div className="row">
          <div className="col-md-5" data-aos="fade-zoom-in">
            <h1>{info?.label}</h1>
            <div className="home-text">
              <div className="Line"></div>
              <h4 className="text-white py-4">{info?.keywords}</h4>
              <a className="WhiteButton" href="/BannerContact">
                {t("Contact")}
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="Car">
        <img className="home-car" src={CarImg} alt="CarImg" />
      </div>
    </div>
  );
}
