import axios from "axios";
const FetchData = async (endpoint, lang) => {
  const BaseUrl = process.env.REACT_APP_BASE_URL_API;
  console.log(BaseUrl + endpoint + `?lang=${lang == "ar" ? 2 : 1}`);
  try {
    const response = await axios.get(
      BaseUrl + endpoint + `?lang=${lang == "ar" ? 2 : 1}`
    );
    if (!response) {
      return "Error Fetch Data";
    }

    return response.data;
  } catch {
    throw new Error("Error fetching data");
  }
};

export default FetchData;
