import { useQuery } from "@tanstack/react-query";
import { BannerAffiated } from "./components/BannerAffiated";
import { AllCompany } from "./components/company";
import { useTranslation } from "react-i18next";
import FetchData from "../../http/FetchData";
import { endpoit } from "../../http/URLs";

export const Affiated = () => {
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const { data: officesData } = useQuery({
    queryKey: [`officesData${lang}`, lang],
    queryFn: () => FetchData(endpoit.HomeGetOffices, lang),
  });
  console.log(officesData);
  return (
    <>
      <BannerAffiated data={officesData?.offices?.name} />
      <AllCompany data={officesData?.officesList} />
    </>
  );
};
