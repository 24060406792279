import imag from "../../../assets/images/About  circle.png";
import carimage from "../../../assets/images/343-Photoroom 1.png";
import { useTranslation } from "react-i18next";

export const SecondAbout = ({ data }) => {
  const { i18n } = useTranslation();
  return (
    <div className="About mt-5" id="About">
      <div className="container">
        <div className="row">
          <div className="col-md-7 position-relative">
            <img src={imag} alt="imagess" className="w-75" id="backcar" />
            <div
              className="car2"
              data-aos={i18n.language === "ar" ? "fade-left" : "fade-right"}
            >
              <img src={carimage} alt="carimage" className="about-car" />
            </div>
          </div>
          <div className="col-md-5 mt-5" data-aos="fade-up">
            <p>
              <span dangerouslySetInnerHTML={{ __html: data }} />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
