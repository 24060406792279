import imag from "../../../assets/images/Group 6.png";
import carimage from "../../../assets/images/343-Photoroom 1.png";
import { useTranslation } from "react-i18next";

export const SecondSection = ({ data }) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="About mt-5" id="About">
      <div className="container">
        <div className="row">
          <div className="col-md-7 position-relative">
            <h1 className="text-black">
              {t("who")}
              <br></br> {t("we?")}
            </h1>
            <img src={imag} alt="imagess" className="w-100" />
            <div
              className="car"
              data-aos={i18n.language === "ar" ? "fade-left" : "fade-right"}
            >
              <img className="about-car" src={carimage} alt="carimage" />
            </div>
          </div>
          <div className="col-md-5 mt-5" data-aos="fade-up">
            <p>
              <span dangerouslySetInnerHTML={{ __html: data }} />
            </p>
            <a href="BannerAbout" className="RedButton my-3">
              {t("more")}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
