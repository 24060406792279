import SectionOne from "./components/sectionOne";
import { SecondSection } from "./components/sectionTwo";
import { ThirdSection } from "./components/thirdSection";
import { FourSection } from "./components/fourSection";
import { FiveSection } from "./components/fiveSection";
import ComplaintInnovationForms from "./components/sevenSection";
import { useQuery } from "@tanstack/react-query";
import { endpoit } from "../../http/URLs";
import FetchData from "../../http/FetchData";
import { useTranslation } from "react-i18next";

export const HomePage = () => {
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const { data: pages } = useQuery({
    queryKey: [`pages${lang}`, lang],
    queryFn: () => FetchData(endpoit.HomeGetPages, lang),
  });

  return (
    <div>
      <SectionOne info={pages?.hometext} />
      <SecondSection data={pages?.aboutHome?.text} />
      <ThirdSection />
      <FourSection />
      <FiveSection />
      <ComplaintInnovationForms />
    </div>
  );
};
