import { Form } from "react-router-dom";
import { BannerContact } from "./components contact/BannerContact";
import ContactForm from "./components contact/ContactForm";
import { Map } from "./components contact/map";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import FetchData from "../../http/FetchData";
import { endpoit } from "../../http/URLs";

export const ContactUs = () => {
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const { data: pages } = useQuery({
    queryKey: [`pages${lang}`, lang],
    queryFn: () => FetchData(endpoit.HomeGetPages, lang),
  });

  return (
    <div>
      <BannerContact />
      <ContactForm data={pages} />
      <Map map={pages?.map} />
    </div>
  );
};
