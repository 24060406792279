import { useTranslation } from "react-i18next";
import { BannerBenefits } from "./components/BannerBenefits";
import { SecondSection } from "./components/secondeSection";
import { useQuery } from "@tanstack/react-query";
import FetchData from "../../http/FetchData";
import { endpoit } from "../../http/URLs";

export const Benefits = () => {
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const { data: getFeatures } = useQuery({
    queryKey: [`getFeatures${lang}`, lang],
    queryFn: () => FetchData(endpoit.GetFeatures, lang),
  });

  return (
    <>
      <BannerBenefits data={getFeatures?.features} />
      <SecondSection data={getFeatures?.featuresList} />
    </>
  );
};
