import { useTranslation } from "react-i18next";
import { BannerGoals } from "./componentsGoals/BannerGoals";
import { GoalsAbout } from "./componentsGoals/BoxesGoals";
import { useQuery } from "@tanstack/react-query";
import { endpoit } from "../../http/URLs";
import FetchData from "../../http/FetchData";

export const Goals = () => {
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const { data: associationGoals } = useQuery({
    queryKey: [`associationGoals${lang}`, lang],
    queryFn: () => FetchData(endpoit.GetAssociationGoals, lang),
  });

  return (
    <div>
      <BannerGoals data={associationGoals?.associationGoals?.name} />
      <GoalsAbout data={associationGoals?.associationGoalsList} />
    </div>
  );
};
